@import "../../assets/partials/var";
@import "../../assets/partials/fonts";
@import "../../assets/partials/mixins";

.media.align-center {
  align-items: center;
  .media-heading {
    margin-bottom: 12px;
  }
  .date-time,
  .talent {
    margin-bottom: 0;
  }
  &.small {
    .media-left {
      width: 50px;
      height: 50px;
    }
    .media-body {
      padding-left: 10px;
      .media-heading {
        margin-bottom: 5px;
      }
    }
  }
}
.justify-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pod-count {
  font-size: 13px;
  color: #474747;
  text-align: right;
  line-height: 18px;
  .count {
    @include font($e-bold-font, 20px, 27px, 400);
    display: block;
  }
  small {
    font-size: 13px;
  }
}

.tracks-wrapper{
  margin: 0 -19px 0 0;
  padding: 0 0 11px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 90px;
    margin-right: 19px;
    margin-bottom: 10px;

    .track-icon {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      background: #fff2f8 url(../../assets/images/music-icon.svg  ) center no-repeat; 
      margin-bottom: 10px;
      font-size: 0;
    }
    span{
      display: flex;
      color: #121622;
      margin-bottom: 6px;
      @include font($semi-font, 14px, 19px, 400);
      word-break: break-word;
      &.time {
        font-size: 12px;
        color: #a0a0a0;
        line-height: 1;
      }
    }
  }
}
.pod {
  .data-box {
    margin-bottom: 25px;
  }
}
.btn-row {
  text-align: right;
  .btn {
    color: $primary-clr;
    @include font($semi-font, 12px, 16px, 400);
    display: inline-block;
    background: none;
    padding: 0;
    height: auto;
    width: auto;
    &.btn-secondary:not(:disabled):not(.disabled):active,
    &.btn-secondary:active,
    &:hover,
    &:focus {
      background: none;
      color: $primary-clr;
    }
    &+.btn {
      margin-left: 20px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
