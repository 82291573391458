@import "../../../assets/partials/var";
@import "../../../assets/partials/fonts";
@import "../../../assets/partials/mixins";

.songs {
  position: relative;
  padding: 16px;
  background: #fff;
  border-radius: 24px;
  .empty {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #212121;
    letter-spacing: -0.02em;
    opacity: 0.6;
    margin: 10px;
  }
  .arrow-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: relative;
    top: 40%;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #fff;
    color: #212121;
    border: 2px solid rgba(#000, 0.2);
    &:active {
      transform: scale(0.95);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .card-song {
    width: 100%;
    background-color: rgba(#f2f2f2, 0.5);
    border-radius: 8px;
    margin: 0 8px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .card-info {
      width: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(#921950, 0.05);
      border-radius: 8px;
      &.active {
        background-color: rgba(#921950, 0.2);
      }
    }
    .check {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      margin: 6px auto;
      background: #fff;
      border: 2px solid #921950;
      box-shadow: 1px 2px 5px rgba(#212121, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.active {
        background: #921950;
      }
      .check-icon {
        width: 18px;
        height: 18px;
        color: #fff;
      }
    }
    .card-img {
      position: relative;
      width: 79px;
      min-height: 90px;
      height: max-content;
      background: #efe9e9;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      overflow: hidden;
    }
    .card-title {
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.06em;
      color: #212121;
      margin: 0;
      padding: 0 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .play-song {
      width: 53px;
      min-width: 53px;
      height: 53px;
      border-radius: 100%;
      background-color: #921950;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      margin-right: 10px;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      .icon-play {
        width: 24px;
        height: 24px;
        color: #fff;
      }
    }
    .list {
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
      .title {
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #212121;
        letter-spacing: -0.02em;
        margin-bottom: 1px;
        text-align: left;
      }
      .partner {
        position: relative;
        display: flex;
        align-items: center;
        padding: 2px 0;
        .cover {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(#921950, 0.1);
          .image {
            position: relative;
            width: 100%;
            height: 100%;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            object-fit: cover;
            overflow: hidden;
            background: #efe9e9;
          }
          .alias {
            font-weight: bold;
            font-size: 20px;
            line-height: 18px;
            color: #212121;
          }
        }
        .info {
          padding: 8px;
          .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #212121;
            letter-spacing: -0.02em;
            margin-bottom: 3px;
            text-align: left;
          }
          .description {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #212121;
            letter-spacing: -0.02em;
            margin: 0;
          }
        }
      }
    }
  }
}
