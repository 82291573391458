@import "../../../assets/partials/var";
@import "../../../assets/partials/fonts";
@import "../../../assets/partials/mixins";

.header-wrap {
  padding: 43px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    /* font-size: 40px; */
    margin: 0;
    line-height: 53px;
    font-family: $heading-font;
  }
  .user-info {
    display: flex;
    align-items: center;
    .notification-bar {
      position: relative;
      margin-right: 34px;
      .fade {
        position: absolute;
        width: 340px;
        right: -10px;
        top: 7px;
        box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.1);
        &.show {
          z-index: 9;
        }
      }
      .list-group {
        border: 0;
        max-height: 503px;
        overflow-y: auto;
      }
      .view-more-wrap {
        background: #fff;
        padding: 20px;
        text-align: right;
        .view-btn {
          color: $primary-clr;
          cursor: pointer;
          background: url(../../../assets/images/forward-arrow.svg) right center
            no-repeat;
          padding-right: 25px;
          line-height: 1;
          display: inline-block;
          font-size: 13px;
        }
      }
      .list-group-item {
        position: relative;
        display: block;
        padding: 15px 0 0;
        background-color: #fff;
        border: 0;
        &.new {
          background: $drop-hover;
          border-bottom: 1px solid white;
          .inner {
            border: 0;
          }
        }
        .inner {
          margin-left: 30px;
          padding-right: 35px;
          padding-left: 16px;
          padding-bottom: 15px;
          border-bottom: 1px solid #f5f5f5;
          font-size: 14px;
          line-height: 22px;
          position: relative;
          &:before {
            position: absolute;
            width: 6px;
            height: 6px;
            background: $primary-clr;
            border-radius: 100%;
            left: 0;
            top: 8px;
            content: "";
          }
          .time-date {
            font-size: 12px;
            color: #aaa;
          }
          strong {
            font-family: $bold-font;
            font-weight: 400;
          }
          small {
            font-size: 12px;
            color: #ababab;
            display: block;
            margin-top: 7px;
            line-height: 1;
          }
        }
      }
    }

    .notification-btn {
      cursor: pointer;
      &.new {
        position: relative;
        &:after {
          width: 10px;
          height: 10px;
          background: #e65d5d;
          border-radius: 100%;
          content: "";
          position: absolute;
          top: 1px;
          right: 2px;
        }
      }
    }
  }
  .dropdown {
    .dropdown-toggle {
      padding: 0;
      height: auto;
      background: none;
      color: #000;
      border: 0;
      font-size: 14px;
      vertical-align: top;
      margin-top: 3px;
      font-family: $reg-font;
      &:after {
        content: "";
        border-top: 0;
        vertical-align: top;
        background: url(../../../assets/images/drop-arrow.svg) center no-repeat;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin: -1px 0 0 7px;
      }
      &:focus,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus,
      &:active,
      &:hover {
        background: none;
        color: $primary-txt;
      }
    }
    .dropdown-menu {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      border: 0;
      padding: 0;
      min-width: 130px;
      overflow: hidden;
      .dropdown-item {
        border: 0;
        padding: 13px 20px 13px 10px;
        @include font($semi-font, 12px, 1, 400);
        line-height: 1;
        &:hover {
          color: $primary-clr;
          background: $drop-hover;
        }
      }
    }
  }
}
.main-container {
  min-height: 100vh;
  background: $main-bg;
  .content-box {
    width: calc(100% - 210px);
    margin-left: 210px;
    padding: 0 54px 30px 30px;
  }
}

.card-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  .card {
    border: 0;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.04);
    border-radius: 18px;
    text-align: left;
    align-items: flex-start;
    padding: 34px 30px 29px;
    width: 218px;
    margin: 0 20px 20px 0;
  }
  .card-img {
    width: auto;
  }
  .card-body {
    padding: 0;
    max-width: 87%;
    margin-top: 13px;
  }
  .card-title {
    @include font($e-bold-font, 26px, 35px, 400);
    margin-bottom: 3px;
    color: $primary-txt;
  }
  .card-text {
    @include font($semi-font, 14px, 18px, 400);
    color: $desc-txt;
    margin-bottom: 14px;
  }
  .more-detail {
    background: url(../../../assets/images/forward-arrow.svg) right center
      no-repeat;
    color: $primary-clr;
    @include font($reg-font, 13px, 24px, 400);
    border: 0;
    padding: 0 35px 0 0;
    height: auto;
    text-align: left;
    width: auto;
    &:hover,
    &:focus {
      background: url(../../../assets/images/forward-arrow.svg) right center
        no-repeat;
      color: $primary-clr;
    }
  }
}

.graph-wrap {
  background: white;
  border-radius: 15px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.04);
  padding: 31px 30px 30px;
  margin: 10px 0 30px;
  .total-revenue {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .right {
      .form-control {
        line-height: 1;
        height: 40px;
        padding: 6px 35px 6px 10px;
        font-size: 15px;
        min-width: 161px;
        margin-top: 3px;
      }
    }
  }
  .title {
    color: $desc-txt;
    display: block;
    margin-bottom: 11px;
    @include font($bold-font, 16px, 1, 400);
  }
  .revenue {
    color: $primary-txt;
    display: block;
    @include font($e-bold-font, 26px, 35px, 400);
    sup {
      font-size: 14px;
      vertical-align: top;
      margin: 17px 6px 0 0;
      font-family: $semi-font;
      display: inline-block;
    }
  }
  .revenue-wrap {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 14px;
      font-family: $semi-font;
    }
  }
  .graph-box {
    padding: 24px 0 30px;
  }
  .headlineplan {
    border: 1px solid #ededed;
    padding: 21px 20px 30px;
    width: calc(50% - 15px);
  }
}
.modal {
  .modal-right {
    height: 100vh;
    margin: 0;
    right: 0;
    background: #fff;
    width: 369px;
    padding: 0;
    position: absolute;
    top: 0;
  }
  .modal-content {
    background-color: #fff;
    border-radius: 0;
    height: 100%;
    padding: 30px 30px 40px;
    border: 0;
    overflow-y: auto;
  }
  .modal-header {
    border-radius: 0;
    padding: 0 0 13px;
    border: 0;
    @include font($bold-font, 18px, 24px, 400);
    .close {
      padding: 0;
      margin: 0;
      opacity: 1 !important;
      background: url(../../../assets/images/close-menu.svg) center no-repeat;
      font-size: 0;
      width: 24px;
      height: 24px;
    }
  }
  .modal-body {
    padding: 0;
    display: flex;
    & > * {
      flex: 1 1 auto;
      padding-bottom: 94px;
      .btn-wrap {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin: 0;
        .btn + .btn-outline-secondary {
          padding: 0;
          margin: 30px 0 0;
          border: 0;
          color: $primary-clr;
          text-decoration: underline;
          font-size: 14px;
          height: auto;
          &:focus,
          &:active,
          &:hover {
            text-decoration: none;
            background: none;
          }
        }
      }
    }
    .subhead {
      @include font($bold-font, 16px, 22px, 400);
      color: $primary-txt;
      padding: 7px 0px 19px;
      display: block;
    }
  }
  &.fade {
    .modal-right {
      transform: translate(100%, 0);
    }
  }
  &.show {
    .modal-right {
      transform: none;
    }
  }
  .modal-small {
    width: 280px;
    margin-top: 90px;
    .modal-content {
      padding-bottom: 30px;
    }
    .modal-body > * {
      padding-bottom: 0;
      .msg {
        max-width: 172px;
        margin: 0 auto 30px;
        font-size: 15px;
        text-align: center;
        font-family: $semi-font;
      }
    }
    .action-wrap {
      display: flex;
      justify-content: space-between;
      .btn {
        height: 35px;
        font-size: 14px;
        padding: 8px 8px 7px;
        min-width: 100px;
        width: auto;
      }
    }
  }
  .modal-crop {
    .modal-body {
      flex-direction: column;
      & > * {
        padding-bottom: 0;
      }
      .action-wrap {
        padding: 20px 0 0;
        display: flex;
        justify-content: space-between;
        .btn {
          width: auto;
          min-width: 150px;
        }
      }
    }
  }
}
.searched-filters {
  margin-top: 71px;
  margin-bottom: -45px;
  position: relative;
  z-index: 1;
  .text-btn {
    padding: 0;
    height: auto;
    border: 0;
    line-height: 1;
    background: none;
    font-size: 12px;
    color: $primary-clr;
    font-family: $bold-font;
    box-shadow: none;
    text-decoration: underline;
    vertical-align: top;
    margin-top: 9px;
    &:hover {
      text-decoration: none;
    }
  }
  .tags {
    border: 1px solid $bc-clr;
    border-radius: 3px;
    padding: 4px 23px 6px 9px;
    line-height: 1;
    display: inline-block;
    font-size: 12px;
    color: #353535;
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    .cross {
      background: url(../../../assets/images/close-grey.svg) 0 0 no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0px;
      top: 1px;
      background-size: 20px;
      font-size: 0;
      cursor: pointer;
    }
  }
}
.data-search-bar {
  display: flex;
  justify-content: space-between;
  background-color: $main-bg;
  min-height: 56px;
  margin-bottom: -56px;
  padding: 10px 0;
  position: relative;
  z-index: 2;
  align-items: center;
  .right-bar {
    display: flex;
    align-items: center;
  }
  h3 {
    margin-bottom: 20px;
    padding: 0;
    font-size: 20px;
  }
  .search-wrap {
    width: 237px;
    margin: 0 10px 0 0;
    position: relative;

    .search-cross {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 7px;
      top: 8px;
      background: url(../../../assets/images/close-grey.svg) center no-repeat;
      font-size: 0;
      cursor: pointer;
    }
    .form-control {
      height: 40px;
      padding: 10px 32px 10px 39px;
      font-size: 12px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      border: 0;
      background: white url(../../../assets/images/icon-search.svg) 10px 8px
        no-repeat;
      &::-webkit-input-placeholder {
        /* Edge */
        color: #a0a0a0;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0a0a0;
      }

      &::placeholder {
        color: #a0a0a0;
      }
    }
  }
  .filter-btn {
    width: 91px;
    height: 40px;
    @include font($reg-font, 12px, 19px, 400);
    padding: 8px;
    border: 0;
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06) !important;
    color: $primary-txt;
    padding-left: 8px;
    align-items: center;
    display: flex;
    &:before {
      background: url(../../../assets/images/icon-filter.svg) center no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      vertical-align: top;
      content: "";
      display: inline-block;
    }
    &.btn:not(:disabled):not(.disabled):active,
    &.btn-outline-secondary:not(:disabled):not(.disabled):active,
    &:active,
    &:focus,
    &:hover {
      color: $primary-txt;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06) !important;
      background: white;
    }
  }
}

.custom-table {
  background-color: inherit;
  .name {
    @include font($semi-font, 12px, 16px, 400);
    margin-bottom: 3px;
    display: block;
  }
  .email-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    display: block;
    font-size: 11px;
    color: #525252;
    @media all and (max-width: 1365px) {
      max-width: 100%;
    }
  }
  .rdt_TableHeadRow,
  .rdt_TableHeader,
  .rdt_Table {
    background: none;
    border: 0;
  }
  .rdt_TableHeader {
    min-height: 40px;
    margin-bottom: 34px;
    padding: 0 0 0 2px;
    font-size: 20px;
  }
  .gnahTY {
    width: calc(100% - 2px);
    margin: 0 auto;
  }
  .rdt_TableHeadRow {
    min-height: 0;
    padding-bottom: 11px;
  }
  .rdt_TableRow {
    margin-bottom: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
    border: 0;
    border-radius: 6px;
    padding: 10px 0 9px;
    min-height: 0;
    color: $primary-txt;
  }
  .rdt_TableCol {
    font-size: 13px;
    min-width: 0;
    max-width: inherit;
    color: $primary-txt;
    .rdt_TableCol_Sortable:hover {
      color: $primary-txt;
    }
    &:first-child {
      min-width: 22%;
      padding: 0 0 0 15px;
    }
    &:last-child {
      max-width: 35px;
      padding: 0 15px 0 0;
    }
    &:nth-child(1),
    &:nth-child(4) {
      min-width: 17%;
      width: 130px;
    }
    &:nth-child(4) {
      @media all and (max-width: 1399px) {
        max-width: 195px;
      }
    }
    &:nth-child(1) {
      & > div {
        margin-left: 45px;
        @media all and (max-width: 1299px) {
          margin-left: 0;
        }
      }
    }
    .sc-kEqYlL,
    .sc-fzpans {
      font-size: 8px;
      margin: -3px 0 0 3px;
    }
    @media all and (max-width: 1259px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .rdt_TableCell {
    min-width: 0;
    max-width: inherit;
    color: $primary-txt;
    font-size: 12px;
    &:first-child {
      min-width: 22%;
      padding: 0 0 0 15px;
    }
    &:last-child {
      max-width: 35px;
      padding: 0 15px 0 0;
    }
    &:nth-child(1),
    &:nth-child(4) {
      min-width: 17%;
      width: 130px;
    }
    &:nth-child(4) {
      @media all and (max-width: 1399px) {
        max-width: 195px;
      }
    }
  }
  .action-wrap {
    .dots-btn {
      display: block;
      width: 20px;
      height: 20px;
      font-size: 0;
      background: url(../../../assets/images/more-icon.svg) center no-repeat;
      cursor: pointer;
    }
    .child-wrap {
      display: none;
      position: absolute;
      z-index: 2;
      right: 13px;
      top: 4px;
      width: 130px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      .item {
        padding: 11px 10px 10px;
        display: block;
        font-size: 13px;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &:hover {
      .child-wrap {
        display: block;
      }
    }
  }
  .rdt_Pagination {
    background: none;
    border: 0;
  }
  .user-wrapper {
    width: 100%;
    a.link-wrap {
      display: flex;
      width: 100%;
      color: $primary-txt;
      &:hover {
        text-decoration: none;
      }
      img,
      .icon {
        border-radius: 100%;
        width: 33px;
        height: 33px;
        display: block;
      }
      .user-detail {
        width: calc(100% - 33px);
        padding-left: 12px;
      }
    }
  }
  &.with-5-col {
    .rdt_TableCell:last-child,
    .rdt_TableCol:last-child {
      max-width: initial;
    }
    a.link-wrap {
      align-items: center;
    }
    .remove {
      cursor: pointer;
      color: #ff0000;
      &:hover {
        text-decoration: underline;
      }
    }
    .rdt_TableCell:nth-child(4) {
      div:first-child {
        white-space: normal;
        line-height: 1.5;
      }
    }
  }
  &.user-table {
    .rdt_TableCell,
    .rdt_TableCol {
      &:nth-child(2) {
        @media all and (max-width: 1499px) {
          max-width: 140px;
          width: 140px;
        }
      }
      &:nth-child(3) {
        @media all and (max-width: 1499px) {
          max-width: 115px;
          width: 115px;
        }
      }
      &:nth-child(5) {
        padding: 0px 10px;
        max-width: 105px;
        width: 105px;
        text-align: center;
      }
      &:nth-child(6) {
        padding: 0px 10px;
        max-width: 87px;
        width: 87px;
        text-align: center;
      }
      &:nth-child(7) {
        padding: 0px 10px;
        max-width: 90px;
      }
    }
  }
}

/* User detail page */
.header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
  &.minus-space {
    margin-bottom: -45px;
    justify-content: flex-end;
    .create-podcast {
      position: relative;
      z-index: 2;
      min-width: 150px;
      .btn.btn-primary {
        height: 40px;
      }
    }
  }
  .title-wrap {
    h3 {
      font-size: 20px;
      line-height: 27px;
      margin: 0 0 10px;
    }
  }
  .breadcrumb {
    background: none;
    line-height: 16px;
    font-size: 12px;
    padding: 0;
    margin: 0;
    a {
      color: $bc-clr;
      @include font($reg-font, 12px, 16px, 400);
    }
    .breadcrumb-item {
      @include font($bold-font, 12px, 16px, 400);
      & + .breadcrumb-item {
        padding-left: 4px;
        &:before {
          display: inline-block;
          padding-right: 3px;
          color: $bc-clr;
          content: ">";
          vertical-align: top;
          margin-top: 0px;
        }
      }
      &.active {
        color: $primary-clr;
        &:before {
          color: $primary-clr;
        }
      }
    }
  }
  .total-spent {
    text-align: right;
    color: #434343;
    .revenue {
      display: block;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 1px;
      @include font($e-bold-font, 20px, 27px, 400);
    }
    small {
      display: block;
      @include font($reg-font, 13px, 18px, 400);
    }
  }
}

.white-bg-row {
  position: relative;
  z-index: 2;
  & > * {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    padding: 20px;
    border-radius: 6px;
    flex: initial;
    &.past {
      cursor: pointer;
    }
    &:not([class]) {
      padding: 0;
      background: none;
    }
  }
  &.wide {
    & > * {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.media {
  .media-left {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .media-body {
    width: calc(100% - 80px);
    padding-left: 20px;
  }
  .media-heading {
    margin-bottom: 5px;
    @include font($bold-font, 16px, 22px, 400);
    .highlight {
      color: $primary-clr;
      background: #ffdfed;
      padding: 6px 16px;
      margin-left: 16px;
      border-radius: 4px;
      @include font($reg-font, 12px, 1, 400);
    }
  }
  .date-time,
  .talent {
    line-height: 18px;
    font-size: 13px;
    color: $bc-clr;
    margin-bottom: 10px;
    display: block;
    &.sep {
      padding: 0 !important;
      margin-right: -2px;
    }
  }
  .speciality {
    .tags {
      font-size: 10px;
      color: $primary-clr;
      padding: 1px 10px 3px;
      border: 1px solid $primary-clr;
      border-radius: 4px;
      line-height: 14px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  &.square-box {
    flex-direction: column;
    align-items: center;
    width: 81px;
    margin-right: 20px;
    margin-bottom: 20px;
    .media-left {
      width: 81px;
      height: 81px;
      border-radius: 6px;
      position: relative;
      &.vedio {
        &:after {
          position: absolute;
          width: 38px;
          height: 38px;
          background: url(../../../assets/images/play.svg) 0 0 no-repeat;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          content: "";
          cursor: pointer;
        }
      }
    }
    .media-body {
      padding: 0;
      width: 100%;
    }
    .media-heading {
      color: #121622;
      margin: 5px 0 0;
      @include font($semi-font, 14px, 20px, 400);
      word-break: break-word;
    }

    &.with-space {
      margin-right: 40px;
    }
  }
}

.create-head {
  .head-divider {
    width: 100%;
    height: 1px;
    background-color: rgb(228, 228, 228);
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .head-title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
  }
  .file-field {
    border-radius: 6px;
  }
  .add-file-btn {
    width: 100px;
    height: 100px;
    background: #fff2f8;
    border: 1px dashed #921950;
    border-radius: 6px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &.full {
      border-color: rgb(79, 198, 78);
      background-color: rgba(79, 198, 78, 0.05);
    }
    .form-control-file {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    & .btn-plus {
      color: $primary-clr;
      display: block;
      margin-bottom: 10px;
      @include font($semi-font, 50px, 20px, 400);
    }
    & .text-file {
      color: $primary-clr;
      display: block;
      margin-bottom: 10px;
      @include font($semi-font, 12px, 20px, 400);
    }
  }
  .selected-photo-files {
    padding-top: 4px;
    max-height: calc(100vh - 573px);
    overflow-y: auto;
    margin-bottom: -22px;
    @media all and (max-height: 767px) {
      max-height: 289px;
    }
    .photo-file-list {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.04);
      border: 1px solid #f7f7f7;
      padding: 14px 13px 13px;
      border-radius: 6px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .file-title {
        color: $primary-clr;
        @include font($reg-font, 14px, 19px, 400);
        width: calc(100% - 20px);
        padding-right: 15px;
        word-break: break-word;
      }
      .remove-btn {
        width: 20px;
        height: 20px;
        background: url(../../../assets/images/close-menu.svg) center no-repeat;
        font-size: 0;
        cursor: pointer;
        background-size: 20px;
      }
    }
  }
}

.create-pod {
  .customswitch {
    margin-bottom: 20px;
    padding-top: 7px;
  }
  .file-field {
    overflow: hidden;
    border-radius: 6px;
  }
  .add-file-btn {
    background: #fff2f8;
    border: 2px dashed #921950;
    border-radius: 6px;
    padding: 17px 20px 19px;
    text-align: center;
    position: relative;
    margin: -1px;
    .form-control-file {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
    &:before {
      content: "Browse Audio File";
      color: $primary-clr;
      display: block;
      margin-bottom: 4px;
      @include font($semi-font, 14px, 20px, 400);
    }
    &:after {
      content: "WAV and MP3";
      color: #bea4af;
      font-size: 12px;
      display: block;
      line-height: 17px;
    }
  }
  .selected-audio-files {
    padding-top: 4px;
    max-height: calc(100vh - 573px);
    overflow-y: auto;
    margin-bottom: -22px;
    @media all and (max-height: 767px) {
      max-height: 289px;
    }
    .audio-file-list {
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.04);
      border: 1px solid #f7f7f7;
      padding: 14px 13px 13px;
      border-radius: 6px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .file-title {
        color: $primary-clr;
        @include font($reg-font, 14px, 19px, 400);
        width: calc(100% - 20px);
        padding-right: 15px;
        word-break: break-word;
      }
      .remove-btn {
        width: 20px;
        height: 20px;
        background: url(../../../assets/images/close-menu.svg) center no-repeat;
        font-size: 0;
        cursor: pointer;
        background-size: 20px;
      }
    }
  }
}

.issue-description {
  color: #525252;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #f0f0f0;
  width: 100%;
}
.reply-form {
  .form-group {
    margin-bottom: 20px;
    &.action-wrap {
      margin-bottom: 0;
    }
    label {
      font-size: 16px;
      color: $primary-txt;
      line-height: 22px;
      margin-bottom: 8px;
    }
    textarea.form-control {
      height: 151px;
    }
  }
}

.childAction {
  display: block !important;
}
