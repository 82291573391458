$primary-clr: #921950;
$heading-clr: #1d1e1f;
$primary-txt: #000;
$input-bdr: #c4d3dc;
$label-clr: #666666;
$tab-info: #bcbcbc;
$bc-clr: #8b8b8b;
$placeholder-clr: #929292;
$subtxt-clr: #595959;
$headingclr: $heading-clr;
$btn-disabled:#b84d7e;
$error: #ff0000;
$main-bg: #f8f8f8;
$drop-hover: #fff2f8;
$desc-txt: #6d7278;
$active: #6fb84c;
$inactive: #ff3b30;
$tabs-bdr: #d8d8d8;


