@import "../../assets/partials/var";
@import "../../assets/partials/fonts";
@import "../../assets/partials/mixins";

.main-scroll {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  &::-webkit-scrollbar {
    width: 10px;
    background: rgba(#921950, 0.1);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #921950;
  }
}
.header-parlour {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 76px;
  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.03em;
    color: #212121;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0;
  }
}
.parlours {
  position: relative;
  padding: 16px;
  background: #fff;
  border-radius: 24px;
  .empty {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #212121;
    letter-spacing: -0.02em;
    opacity: 0.6;
    margin: 10px;
  }
  .arrow-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: relative;
    top: 40%;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #fff;
    color: #212121;
    border: 2px solid rgba(#000, 0.2);
    &:active {
      transform: scale(0.95);
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .card-parlour {
    width: 300px;
    max-width: 100%;
    margin: 0 8px;
    .card-poster {
      position: relative;
      width: 100%;
      height: 250px;
      background-color: rgba(#921950, 0.05);
      border-radius: 24px;
      margin-bottom: 10px;
      border: 6px solid #921950;
      overflow: hidden;
      .card-live {
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 10;
        display: flex;
        align-items: center;
        .card-live-circle {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background-color: $active;
          margin-right: 6px;
        }
        .card-live-text {
          font-weight: 800;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.03em;
          color: $active;
        }
      }
      .card-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .bg-black {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.4);
      }
      .card-info {
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: 0;
        padding: 16px 20px;
        .card-title {
          font-weight: 600;
          font-size: 17px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #fff;
          opacity: 0.8;
          margin: 0;
        }
        .card-description {
          font-weight: 800;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #fff;
          margin: 0;
          margin-bottom: 4px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .card-date {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #fff;
          margin: 0;
        }
      }
      .parlour-video {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 20;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 3px solid #921950;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        .parlour-icon_video {
          position: relative;
          width: 20px;
          height: 20px;
        }
      }
    }
    .card-participants {
      margin-bottom: 24px;
      padding: 0 10px;
      .card-participant {
        margin-bottom: 10px;
        .card-participant_title {
          font-weight: 800;
          font-size: 17px;
          line-height: 30px;
          letter-spacing: -0.03em;
          color: #212121;
        }
        .card-participant_detail {
          display: flex;
          align-items: center;
          .card-participant_photo {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 8px;
            border: 2px solid #921950;
            background-color: rgba(#921950, 0.2);
            &.guest {
              border-color: $active;
              background-color: rgba($active, 0.2);
            }
          }
          .card-participant_name {
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: -0.03em;
            color: #212121;
          }
        }
      }
    }
    .card-actions {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .delete-parlour {
      display: block;
      margin: auto;
      padding: 4px 20px;
      border-radius: 40px;
      border: none;
      background-color: #C9302C;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: -0.03em;
      color: #fff;
      &:active {
        transform: scale(0.98);
        opacity: 0.8;
      }
    }
  }
}
.participant {
  display: flex;
  margin-top: 20px;
  align-items: center;
  .participant-image {
    border-radius: 50%;
  }
  .participant-detail {
    margin-left: 10px;
    .name {
      font-size: 14px;
      font-family: 'nunito_sanssemibold', 'Helvetica Neue', sans-serif;
      line-height: 16px;
      font-weight: 400;
      margin-bottom: 3px;
      display: block;
    }
    .email {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      display: block;
      font-size: 13px;
      color: #525252;
      max-width: 100%;
    }
  }
}
