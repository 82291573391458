@import "../../../assets/partials/var";
@import "../../../assets/partials/mixins";
@import "../../../assets/partials/fonts";

.nav-tabs {
  border-bottom: 1px solid $tabs-bdr;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  .nav-link {
    border: 0;
    border-radius: 0;
    padding: 0 15px 8px;
    text-align: center;
    cursor: pointer;
    @include font($semi-font, 15px, 1, 400);
    color: $primary-txt;
    @media all and (min-width: 991px) {
      min-width: 170px;
    }
    &:hover,
    &.active {
      border-bottom: 2px solid $primary-clr;
      color: $primary-clr;
    }
    &.active {
      font-family: $bold-font;
      background: none;
    }
  }
}
.white-bg-row {
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap;
  &.wide {
    & > *.card {
      width: 100%;
      .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1px;
        font-size: 17px;
        color: $primary-clr;
        font-family: $semi-font;
        &.black {
          color: $primary-txt;
           strong {
            font-size: 16px;
          }
        }
        .purchase-time {
          span {
            display: block;
            color: $primary-txt;
            @include font($semi-font, 12px, 16px, 400);
            &.data {
              color: #525252;
            }
          }
        }
      }
      .price-range {
        margin-bottom: 6px;
        color: #121622;
        @include font($bold-font, 23px, 31px, 400);
      }
      .card-text {
        margin: 0;
        ul {
          font-size: 16px;
          color: #646e76;
          padding-left: 19px;
          margin: 0;
          line-height: 22px;
        }
      }
    }
  }
  & > * {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
    align-items: center;
    border: 0;
    &.media {
      .media-left {
        height: 53px;
        width: 53px;
        border-radius: 8px;
        &.wide {
          width: 80px;
          height: 80px;
          border-radius: 100%;
        }
      }
      .media-body {
        position: relative;
        padding-right: 45px;
        .remove-btn {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 15px;
          color: #ff0000;
          @include font($semi-font, 11px, 15px, 400);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      small {
        font-size: 13px;
        color: #a0a0a0;
        line-height: 18px;
        display: block;
      }
    }
    &.card {
      align-items: initial;
      .card-title {
        display: flex;
        align-items: flex-start;
        color: #121622;
        margin-bottom: 10px;
        justify-content: space-between;
        strong {
          @include font($bold-font, 14px, 18px, 400);
        }
        .card-status {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
          margin-left: 54px;
          text-align: center;
          color: #a0a0a0;
          background: #f5f5f5;
          border-radius: 4px;
          font-weight: 400;
          padding: 3px 12px 4px;
          &.new {
            background: #ffdfed;
            color: $primary-clr;
          }
        }

        .end-date {
           min-width: 74px;
        }
      }
      .post-time {
        color: #a0a0a0;
        margin-bottom: 15px;
        @include font($semi-font, 11px, 15px, 400);
        &.e-space {
          margin-bottom: 25px;
        }
      }
      .card-text {
        margin-bottom: 20px;
        color: #8e8e8e;
        @include font($semi-font, 12px, 1.4, 400);
      }
      .remove-btn {
        font-size: 12px;
        background: none;
        color: $primary-clr;
        font-family: $semi-font;
        border: 0;
        padding: 0;
        height: auto;
        width: auto;
        float: right;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.tab-content > .tab-pane {
  padding: 0;
  &.white-bg {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    padding: 20px;
    border-radius: 6px;
  }
  .edit-profile {
    height: 35px;
    position: absolute;
    width: 135px;
    line-height: 19px;
    font-size: 14px;
    right: -5px;
    top: -86px;
    padding: 7px 10px;
    z-index: 2;
  }
}

.profile-info {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .rating-wrap {
    display: flex;
    align-items: center;
    .rating {
      margin-left: 7px;
      @include font($bold-font, 14px, 19px, 400);
    }
    span[data-forhalf] {
      font-size: 0 !important;
      margin-left: 3px;
      width: 15px;
      height: 15px;
      display: inline-block;
      vertical-align: top;
      &:after {
        content: "red";
        background: url(../../../assets/images/star.svg) 0 0 no-repeat;
        width: 15px;
        height: 15px;
        display: inline-block;
      }
      &[style*="gray"] {
        &:after {
          background: url(../../../assets/images/grey-star.svg) 0 0
            no-repeat;
        }
      }
    }
  }
}
.data-row {
  justify-content: space-between;
  & > * {
    &:last-child {
      flex: inherit;
      max-width: initial;
      width: auto;
      min-width: 110px;
    }
  }
}
.video-row {
  .inner-row {
    display: flex;
    margin-right: -20px;
  }
  .title {
    margin-bottom: 10px;
    display: block;
    @include font($bold-font, 14px, 19px, 400);
  }
}
.data-box {
  @include font($semi-font, 14px, 19px, 400);
  color: #525252;
  margin-bottom: 30px;
  .label {
    font-family: $bold-font;
    margin-bottom: 5px;
    display: block;
    color: $primary-txt;
  }
}
.profile-form {
  .form-row {
    margin-right: -10px;
    margin-left: -10px;
    & > [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .file-field {
    width: 80px;
    margin: 20px auto 30px;
    .pofile-pic {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 100%;
      margin-bottom: 8px;
    }
    .edit-pic {
      display: block;
      background: url(../../../assets/images/edit.svg) 0 0 no-repeat;
      cursor: pointer;
      width: 45px;
      margin: 0 auto;
      position: relative;
      color: $primary-clr;
      &:after {
        content: "edit";
        position: absolute;
        left: 23px;
        right: 0;
        top: -1px;
        width: 22px;
        text-transform: capitalize;
      }
      .form-control-file {
        height: 18px;
        opacity: 0;
        cursor: pointer;
        position: relative;
        z-index: 2;
        margin-left: -116px;
        min-width: 150px;
      }
    }
  }
  .form-title {
    margin: 17px 0 19px;
    display: block;
    @include font($bold-font, 16px, 1, 400);
  }
  .video-url {
    position: relative;
    .form-control {
      padding-right: 55px;
    }
    .remove {
      color: #ff0000;
      position: absolute;
      right: 11px;
      bottom: 18px;
      cursor: pointer;
      @include font($semi-font, 11px, 15px, 400);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .action-wrap {
    margin-top: -8px;
    padding-bottom: 40px;
    text-align: right;
    .btn {
      width: auto;
      min-width: 199px;
      & + .btn {
        margin-left: 20px;
      }
    }
    .add-url-row {
      padding: 0px 5px 0px 34px;
      display: inline-block;
      line-height: 24px;
      color: $primary-clr;
      @include font($semi-font, 12px, 24px, 400);
      cursor: pointer;
      background: url(../../../assets/images/add-row-icon.svg) 0 0
        no-repeat;
    }
    & + .action-wrap {
      margin-top: 0;
      padding-bottom: 20px;
    }
  }
}

.talent-wrap {
  display: flex;
  flex-wrap: wrap;
  &>.talent {
    &+.talent {
     
      padding-left: 5px;
    }
  }
}