@import "../../../assets/partials/var";
@import "../../../assets/partials/fonts";
@import "../../../assets/partials/mixins";

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 210px;
  height: 100%;
  overflow-y: auto;
  .site-logo {
    display: inline-block;
    margin: 40px 0 0 30px;
    position: fixed;
    top: 0;
    left: 0;
  }
  .navigaiton {
    list-style: none;
    padding: 0;
    margin: 198px 0 0;
    li {
      margin-bottom: 20px;
      small {
        display: block;
        padding: 0 0 10px 18px;
        color: $tab-info;
        line-height: 1;
        @include font($e-bold-font, 11px, 1, 400);
        text-transform: uppercase;
      }
      a {
        font-size: 14px;
        padding: 16px 15px 15px 50px;
        display: inline-block;
        color: $primary-txt;
        @include font($bold-font, 14px, 1, 400);
        &.dashboard {
          background: url(../../../assets/images/unselected-dashboard.svg) 16px
            center no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selected-dashboard.svg) 16px
              center no-repeat;
          }
        }
        &.user {
          background: url(../../../assets/images/unselcted-user.svg) 16px center
            no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selcted-user.svg) 16px center
              no-repeat;
          }
        }
        &.post {
          background: url(../../../assets/images/icons-bulletin.svg) 16px center
            no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/icons-bulletin.svg) 16px
              center no-repeat;
          }
        }
        &.podcast {
          background: url(../../../assets/images/unselected-podcast.svg) 16px
            center no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selected-podcast.svg) 16px
              center no-repeat;
          }
        }
        &.headline {
          background: url(../../../assets/images/unselected-news.svg) 16px
            center no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selected-news.svg) 16px
              center no-repeat;
          }
        }
        &.voteSong {
          background: url(../../../assets/images/unselected-song-contest.svg)
            16px center no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selected-song-contest.svg)
              16px center no-repeat;
          }
        }
        &.parlour {
          background: url(../../../assets/images/unselected-scheduler-parlour.svg)
            16px center no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selected-scheduler-parlour.svg)
              16px center no-repeat;
          }
        }
        &.support {
          background: url(../../../assets/images/unselected-support.svg) 16px
            center no-repeat;
          &.active,
          &:hover {
            background: url(../../../assets/images/selected-support.svg) 16px
              center no-repeat;
          }
        }
        &.active,
        &:hover {
          color: $primary-clr;
          text-decoration: none;
        }
      }
    }
  }
}
