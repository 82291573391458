@import "../../../assets/partials/var";
@import "../../../assets/partials/fonts";
@import "../../../assets/partials/mixins";

.login-wrapper {
  min-height: 100vh;
  .inner-wrapper {
    display: flex;
    .form-wrap {
      width: 45.3%;
      margin: 137px 0 24px 7.8%;
      padding-right: 20px;
      .form-title {
        font-size: 40px;
        line-height: 53px;
        margin-bottom: 39px;
        font-family: $heading-font;
        max-width: 350px;
        color: $heading-clr;
      }
      .sub-text {
        max-width: 350px;
        font-size: 16px;
        line-height: 24px;
        margin: -20px 0 27px;
        color: #595959;
      }
      form {
        max-width: 350px;
      }
      .success-message {
        max-width: 442px;
        position: relative;
        top: calc(50% - 68px);
        margin-top: -144px;
        .sub-text {
          max-width: 100%;
          font-size: 15px;
          margin: 18px 0 30px;
        }
        .success-icon {
          margin-bottom: 10px;
        }
        h3 {
          @include font($bold-font, 24px, 32px, 400);
          color: $heading-clr;
          margin-bottom: 0;
        }
        .form-group {
          max-width: 350px;
          display: inline-block;
          width: 100%;
          a.btn {
            line-height: 1.7;
          }
        }
      }
    }
    .form-banner {
      width: 46.9%;
      background: url(../../../assets/images/branding.png) center 0 no-repeat;
      background-size: cover;

      .site-logo{
        width: 75px;
        height: 75px;
        margin: 24px 0 0 55px;
      }
    }
    .form-desc {
      font-size: 18px;
      margin-bottom: 40px;
      line-height: 30px;
    }

    &.remove-image {
      .form-banner {
        display: none;
      }
      .form-wrap {
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        height: 100vh;
        align-items: center;
        margin: 0;
        .success-message {
          top: 0;
          margin-top: 0;
          max-width: 660px;

          h3 {
            font-size: 2.25rem;
            line-height: 1.25;
          }

          .sub-text {
            font-size: 1.5rem;
            line-height: 1.5;
          }
        }
      }
    }
  }
 
}
