.post-posted-by {
  width: 54px;
  height: 16px;
  margin: 0 94px 6px 0;
  font-family: NunitoSans;
  font-size: 12px;
  padding-bottom: 1rem;
  color: #8b8b8b;
}

.post-user-wrapper {
  width: 100%;
  display: flex;
  padding: 1rem 0;
}

.post-link-wrap {
  display: flex;
  width: 100%;
  color: #000;
}
.post-link-wrap:hover {
    text-decoration: none;
}

.post-user-detail {
  width: calc(100% - 33px);
  padding-left: 12px;
}
.post-name {
  font-size: 12px;
  font-family: 'nunito_sanssemibold', 'Helvetica Neue', sans-serif;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 3px;
  display: block;
}

.post-email-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: block;
  font-size: 11px;
  color: #525252;
  max-width: 100%;
}

.post-talent-type {
  width: min-content;
  margin-right: 10px;
  padding: 2px 14px 2px 15px;
  border-radius: 4px;
  background-color: #ffdfed;
}

.post-title {
  width: 100%;
  overflow-wrap: break-word;
  font-weight: 600;
  font-size: 16px;
}

.post-description {
  width: 100%;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: normal;
  padding: 14px 0;
  color: #525252;
}

.post-response {
  width: 100%;
  overflow-wrap: break-word;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}