@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@font-face {
    font-family: 'nunito_sanssemibold';
    src: url('../fonts/nunitosans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/nunitosans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  @font-face {
    font-family: 'nunito_sansbold';
    src: url('../fonts/nunitosans-bold-webfont.woff2') format('woff2'),
         url('../fonts/nunitosans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  @font-face {
    font-family: 'nunito_sansregular';
    src: url('../fonts/nunitosans-regular-webfont.woff2') format('woff2'),
         url('../fonts/nunitosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  @font-face {
    font-family: 'nunito_sansextrabold';
    src: url('../fonts/nunitosans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/nunitosans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }

  $heading-font: 'Playfair Display', "Helvetica Neue", sans-serif;
  $reg-font: 'nunito_sansregular', "Helvetica Neue", sans-serif;
  $bold-font: 'nunito_sansbold', "Helvetica Neue", sans-serif;
  $semi-font: 'nunito_sanssemibold', "Helvetica Neue", sans-serif;
  $e-bold-font: 'nunito_sansextrabold', "Helvetica Neue", sans-serif;