@import "fonts";
@import "var";
@import "mixins";

* {
  &:focus {
    outline: 0 !important;
  }
}

body {
  color: $primary-txt;
  @include font($reg-font, 14px, 21px, 400);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.active-label {
  color: $active;
  &:hover {
    color: $active;
  }
}
.inactive {
  color: $inactive;
  &:hover {
    color: $inactive;
  }
}

.padd {
  margin-top: -10px;
}

.padding {
  margin-top: -4px;
  padding-bottom: 4px;
  padding-top: 8px;
}

.btn-group {
  display: flex;
}

.form-group {
  margin-bottom: 1.875rem;
  position: relative;
  label {
    @include font($semi-font, 13px, 18px, 400);
    color: $label-clr;
  }
  select.form-control {
    appearance: none;
    -webkit-appearance: none;
    background: url(../images/dropdown-aero.svg) calc(100% - 10px) center
      no-repeat;
    line-height: 1;
    padding-right: 35px;
  }
  input[role="combobox"],
  .form-control {
    border: 1px solid $input-bdr;
    border-radius: 6px;
    height: 50px;
    padding: 14px 10px;
    font-size: 16px;
    color: $primary-txt;
    width: 100%;
    &.error {
      border-color: $error;
    }
    &:focus {
      box-shadow: none;
      border-color: $primary-clr;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px white inset;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: $placeholder-clr;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $placeholder-clr;
    }

    &::placeholder {
      color: $placeholder-clr;
    }
    &:disabled {
      background: #efefef;
    }
  }
  input[role="combobox"] {
    background: url(../images/location.svg) calc(100% - 15px) center no-repeat;
    padding-right: 40px;
  }
  textarea.form-control {
    height: 143px;
    resize: none;
  }
  span.error {
    position: absolute;
    bottom: -18px;
    left: 1px;
    font-size: 11px;
    color: $error;
  }
}
.text-link {
  @include font($semi-font, 14px, 19px, 400);
  color: $primary-clr;
  text-decoration: underline;
  &:hover {
    color: $primary-clr;
    text-decoration: none;
  }
}

.btn {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  @include font($bold-font, 16px, 1, 400);
  padding: 11px 15px 13px;
  &.width-auto {
    width: auto;
    min-width: 150px;
  }
  &.btn-primary {
    background: $primary-clr;
    border: 0;
    color: white;
    &:disabled {
      background: $primary-clr;
      color: $btn-disabled;
      opacity: 1;
    }
  }
  &.btn-outline-primary {
    border-color: $primary-clr;
    color: $primary-clr;
    &:hover {
      color: white;
    }
    &:not(:disabled):not(.disabled).active {
      background: $primary-clr;
      color: white;
      border-color: $primary-clr;
    }
  }
  &.btn-secondry,
  &.btn-secondary {
    background: #e2e2e2;
    border: 0;
    color: $primary-txt;
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:active,
    &:hover {
      background: #e2e2e2;
      color: $primary-txt;
    }
  }
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus,
  &:active,
  &:hover {
    background: $primary-clr;
    outline: 0 !important;
    box-shadow: none !important;
  }
  &.wider {
    width: 100%;
  }
}

.form-control.select {
  padding: 14px 10px;
  height: auto;
  min-height: 50px;
  z-index: 2;
  .css-1okebmr-indicatorSeparator {
    visibility: hidden;
    & + .css-tlfecz-indicatorContainer,
    & + .css-1gtu0rj-indicatorContainer {
      background: url(../../assets/images/dropdown-aero.svg) center no-repeat;
      opacity: 1;
      svg {
        visibility: hidden;
      }
    }
  }
  .css-1gtu0rj-indicatorContainer,
  .css-tlfecz-indicatorContainer {
    opacity: 1 !important;
    padding-right: 0;
    cursor: pointer;
    svg {
      fill: $primary-txt !important;
    }
  }
  .profile-form .form-control.select .css-tlfecz-indicatorContainer:hover,
  .css-tlfecz-indicatorContainer:hover {
    opacity: 1;
    cursor: pointer;
  }
  & > div {
    border: 0;
    height: auto;
    min-height: 10px;
    line-height: 1;
    margin-top: -8px;
    margin-bottom: -8px;
    box-shadow: none !important;
    & ~ div {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
      margin-left: -10px;
      margin-top: 1px;
      & > div {
        padding: 0;
        & > div {
          padding: 13px 20px 13px 10px;
          font-size: 14px;
        }
      }
      .css-1n7v3ny-option {
        color: $primary-clr;
        background: #fff2f8;
      }
    }
    &:active,
    &:focus {
      outline: 0 !important;
    }

    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .css-1rhbuit-multiValue {
    margin: 2px 10px 2px 0;
    min-width: 0;
    box-sizing: border-box;
    border-radius: 4px;
    background: none;
    border: 1px solid #921950;
    position: relative;
    padding-right: 20px;
    div {
      border-radius: 0;
      overflow: hidden;
      padding: 3px 0px 5px 6px;
      line-height: 1;
      font-size: 10px;
      color: $primary-clr;
      & + div {
        position: absolute;
        right: 2px;
        top: -1px;
        color: $primary-clr;
        cursor: pointer;
        &:hover {
          background: none;
        }
      }
    }
  }
  &.with-img {
    & > div {
      div.css-1uccc91-singleValue {
        .img-wrap,
        .email {
          display: none;
        }
        .user-name {
          font-size: 16px;
          color: $primary-txt;
          font-weight: 400;
        }
      }
      & ~ div {
        & > div {
          padding: 0 0 0 15px;
          & > div {
            padding: 15px 15px 15px 0;
            font-size: 14px;
            border-bottom: 1px solid #f0f0f0;
            .list-with-icon {
              display: flex;
              .img-wrap {
                width: 35px;
                height: 35px;
                border-radius: 100%;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .user-info {
                width: calc(100% - 35px);
                padding-left: 10px;
                .user-name {
                  color: $primary-txt;
                  @include font($bold-font, 14px, 19px, 400);
                  margin-bottom: 4px;
                  display: block;
                }
                .email {
                  font-size: 12px;
                  color: #a0a0a0;
                }
              }
            }
            &:last-child {
              border: 0;
            }
          }
        }
        .css-9gakcf-option,
        .css-1n7v3ny-option {
          color: $primary-txt;
          background: #fff;
        }
      }
    }
  }
}
.custom-switch.custom-control {
  padding-right: 105px;
  padding-left: 0;
  label::before {
    left: auto;
  }
  .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
    border-color: $input-bdr;
  }
  .custom-control-label:before {
    right: -6.5rem;
    height: 6px;
    width: 24px;
    border-radius: 4px;
    top: 9px;
    border-color: $input-bdr;
  }
  .custom-control-label:after {
    top: calc(0.25rem + 2px);
    right: calc(-5.9rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    left: auto;
  }
  .custom-control-input:checked ~ .custom-control-label:after {
    background-color: $primary-clr;
    transform: translateX(0.8rem);
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $input-bdr;
    background-color: #c4d3dc;
  }
}

// Sorting icons

.__rdt_custom_sort_icon__ {
  height: 13px;
  width: 5px;
  font-size: 0;
  position: relative;
  &:after,
  &::before {
    content: "";
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 5px solid #000;
    position: absolute;
    top: -1px;
    right: -6px;
  }
  &:after {
    border-top: 5px solid #b8b8b8;
    border-bottom: 0;
    top: 7px;
  }
  &.desc {
    &:before {
      border-bottom-color: #b8b8b8;
    }
    &:after {
      border-top-color: #000;
    }
  }
}

.Toastify__toast-container {
  width: 335px;
  .Toastify__toast {
    background: #6fb84c;
    border-radius: 8px;
    padding: 17px 10px 16px 20px;
    min-height: 54px;
    align-items: center;
    display: flex;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0 6px 0 30px;
    font-size: 14px;
    color: #ffffff;
    font-family: $semi-font;
    background: url(../images/check-green.svg) 0 center no-repeat;
    word-break: break-word;
  }
  .Toastify__close-button {
    background: url(../images/cross-white.svg) center no-repeat;
    width: 15px;
    height: 15px;
    opacity: 1;
    align-self: center;
    svg {
      visibility: hidden;
    }
  }
  & > .Toastify__toast--error {
    background: #bf1401;
    .Toastify__toast-body {
      background: url(../images/toast-error.svg) 0 center no-repeat;
    }
  }
}

.show-pass {
  position: absolute;
  right: 10px;
  top: 43px;
  font-size: 12px;
  color: $heading-clr;
  cursor: pointer;
}
.rc-loading .rc-loading-icon {
  width: 80px !important;
  height: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  left: 0;
  right: 0;
  background: #fff;
}
.rc-loading-icon-default,
.rc-loading-icon-default:after {
  border-radius: 50%;
  width: 5em !important;
  height: 5em !important;
}
.rc-loading-icon-default {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.55em solid rgba($primary-clr, 0.1) !important;
  border-right: 0.55em solid rgba($primary-clr, 0.1) !important;
  border-bottom: 0.55em solid rgba($primary-clr, 0.1) !important;
  border-left: 0.55em solid $primary-clr !important;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear !important;
  animation: load8 1.1s infinite linear !important;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.block-label {
  width: 100% !important;
  text-align: center;
}
.fullScreen-video {
  height: 100vh;
}

.google-covert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 0.25rem;
  border: 0;
  .style-unordered-list {
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
  }
  .custom-style-list {
    border: 0;
    padding: 13px 20px 13px 10px;
    font-size: 12px;
    font-family: "nunito_sanssemibold", "Helvetica Neue", sans-serif;
    font-weight: 400;
    line-height: 1;
    list-style: none;
    cursor: pointer;
    &:hover {
      color: $primary-clr;
      background: $drop-hover;
    }
  }
}

.location-box {
  height: 40px;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden !important;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.location > div {
  display: block !important;
  position: relative;
}
.location > div > div {
  z-index: 9;
  position: absolute !important;
  top: 50px !important;
  left: 0 !important;
  background: white !important;
  max-height: 250px !important;
  width: 100% !important;
  overflow-y: auto !important;
  padding: 0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
}
.location > div > div > div,
.style-list {
  cursor: pointer;
  list-style: none;
  border-bottom: 1px solid #cecece;
  height: 40px;
  padding-left: 20px;
  line-height: 40px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.location > div > div > div:last-child {
  border: 0;
}
.location > div > div > div:hover,
.style-list:hover {
  color: rgba($primary-clr, 1);
  background: rgba($primary-clr, 0.1);
}
.user-search-control > div {
  display: block !important;
  position: relative;
  font-size: 14px;
  .list-with-icon {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    margin: 10px 0 0 10px;
    padding-bottom: 10px;
    .img-wrap {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .user-info {
      width: calc(100% - 35px);
      padding-left: 10px;
      .user-name {
        color: $primary-txt;
        @include font($bold-font, 14px, 19px, 400);
        margin-bottom: 4px;
        display: block;
      }
      .email {
        font-size: 12px;
        color: #a0a0a0;
      }
    }
  }
  &:last-child {
    border: 0;
  }
}
.user-search-control > div > div {
  z-index: 9;
  position: absolute !important;
  top: 50px !important;
  left: 0 !important;
  background: white !important;
  max-height: 250px !important;
  width: 100% !important;
  overflow-y: auto !important;
  padding: 0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.user-search-control input[role="combobox"] {
  background: url(../../assets/images/dropdown-aero.svg) calc(100% - 15px)
    center no-repeat;
  padding-right: 40px;
}
.current-loc-ico {
  height: 17px;
  padding-right: 10px;
}

.rc-loading .rc-loading-mask {
  position: fixed;
}
.pagination {
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  li {
    margin: 0 3px !important;
    &:first-child,
    &:last-child {
      display: none;
    }
    a {
      width: 30px;
      height: 30px;
      display: block;
      border: 1px solid #c4d3dc;
      text-align: center;
      line-height: 28px;
      border-radius: 4px;
      color: #666666;
      font-size: 14px;
      background: white;
      &:hover {
        text-decoration: none;
      }
      &[aria-label="Go to previous page"] {
        background: white url(../../assets/images/l-aero.svg) center no-repeat;
        font-size: 0;
      }
      &[aria-label="Go to next page"] {
        background: white url(../../assets/images/r-aero.svg) center no-repeat;
        font-size: 0;
      }
    }
    &:hover,
    &.active {
      a {
        background: $primary-clr;
        color: white;
        border-color: $primary-clr;
        &[aria-label="Go to previous page"] {
          background: $primary-clr url(../../assets/images/l-aero-w.svg) center
            no-repeat;
          font-size: 0;
        }
        &[aria-label="Go to next page"] {
          background: $primary-clr url(../../assets/images/r-aero-w.svg) center
            no-repeat;
          font-size: 0;
        }
      }
    }
  }
}
.manualCl.form-group select.form-control {
  color: grey !important;
}
.podcast-wrapper {
  display: flex;
  justify-items: center;
}

.gnnnMA {
  background-color: transparent !important;
}
.greenBtn {
  color: #6fb84c;
}
.MaroomBtn {
  color: #921950;
}
.RemoveBtn {
  background-color: #921950;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
}
.justify-content-end {
  justify-content: flex-end;
}
.confirm-alert {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #921950;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}
.confirm-alert > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: #fff;
  color: #921950;
  font-size: 14px;
}
.sc-fodVxV.bMeATW.rdt_TableHeader {
  background: transparent;
}
