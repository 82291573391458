@import "../../assets/partials/var";
@import "../../assets/partials/fonts";
@import "../../assets/partials/mixins";

.main-scroll {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  &::-webkit-scrollbar {
    width: 10px;
    background: rgba(#921950, 0.1);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #921950;
  }
}
.active-vote {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  background: #58d68d;
  border-radius: 24px;
  padding: 4px 16px;
  border: none;
  width: max-content;
  box-shadow: 1px 2px 5px rgba(#000, 0.1);
  cursor: pointer;
  &:disabled {
    opacity: 0.8;
  }
  &.active {
    transform: scale(0.98);
    background: #e74c3c;
  }
  .heart-icon {
    width: 20px;
    height: 20px;
    color: #fff;
    margin-right: 4px;
  }
  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.06em;
    color: #fff;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0;
  }
}
.timer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  .title {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #212121;
    cursor: pointer;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .timer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: auto;
    margin-bottom: 16px;
    .time-number {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.06em;
      color: #212121;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      box-shadow: 1px 2px 5px rgba(#000, 0.1);
    }
  }
}
.header-song {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 76px;
  &.space {
    justify-content: space-between;
  }
  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.06em;
    color: #212121;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    margin: 0;
  }
  .group-song {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: -0.06em;
      color: #212121;
      cursor: pointer;
      padding-bottom: 8px;
      margin: 0;
    }
    .song-check {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-bottom: 10px;
      .check {
        width: 24px;
        height: 24px;
        border-radius: 8px;
        background: #fff;
        border: 2px solid #545454;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active {
          background: #921950;
          border-color: #921950;
        }
        .check-icon {
          width: 18px;
          height: 18px;
          color: #fff;
        }
      }
      .text {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.06em;
        color: #545454;
        cursor: pointer;
        margin: 0;
      }
    }
  }
  .group-button-feature-song {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  .button-feature-song {
    position: relative;
    z-index: 99;
    border: none;
    padding: 7px 16px;
    border-radius: 40px;
    background: #f39c12;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    cursor: pointer;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    &.start {
      background: #58d68d;
    }
    &.end {
      background: #e74c3c;
    }
    &:active {
      opacity: 0.98;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      opacity: 0.8;
      transform: scale(1.05);
    }
  }
}
