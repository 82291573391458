@import "../../assets/partials/var";
@import "../../assets/partials/fonts";
@import "../../assets/partials/mixins";

.content-header {
  background-color: #000;
  padding: 10px 30px;
}
.content {
  padding: 30px
}
